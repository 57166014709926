<template>
  <div class="ob-ed">
    <OnboardingLayout :step="currentStep">
      <template v-slot:content-section>
        <div class="ob-ed__new w-100 ob-date__birth">
          <div class="ob-ed__main">
            <div class="ob-img__responsive_view">
              <img
                src="@/assets/images/onboarding/birthday.svg"
                alt="birthday"
              />
            </div>
            <h2 class="ob-ed__heading">
              {{ $t("onboarding.date_of_birth.heading") }}
            </h2>

            <b-form @submit="onNext" novalidate>
              <div
                :class="[
                  'form-floating',
                  { 'input-date__error': errMsg.length > 0 }
                ]"
              >
                <div class="form-floating__div date-field">
                  <input
                    name="dateofbirth"
                    ref="dateInput"
                    id="dateField"
                    :class="['form-control', { active: isDobValid }]"
                    :placeholder="
                      $t('settings.my_details.general.date_of_birth')
                    "
                    type="date"
                    :value="selectedDate"
                    @input="handleDate($event.target.value)"
                    @focus="handleFocus()"
                    @blur="handleBlur()"
                  />
                  <label for="dateofbirth">
                    {{ $t("settings.my_details.general.date_of_birth") }}
                  </label>
                </div>
              </div>
              <span class="error ml-2 mb-2" v-if="!isDobValid">
                {{ errMsg }}
              </span>

              <Footer :invalid="!isDobValid" :buttonText="$t('general.next')" />
            </b-form>
          </div>
        </div>
      </template>
    </OnboardingLayout>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import OnboardingLayout from "../Layout/OnboardingLayout";
import moment from "moment";
import OnboardingMixin from "@/components/Onboarding/mixin";
import Footer from "@/components/Layout/OnboardingPageLayout/Footer.vue";

export default {
  mixins: [OnboardingMixin],
  components: { OnboardingLayout, Footer },
  data() {
    return {
      value: "",
      isCompleted: false,
      selectedDate: "2000-01-01",
      currentStep: 0,
      form: {
        date_of_birth: ""
      },
      isDobValid: false,
      errMsg: "",
      responsive_view: false,
      currentYear: moment()
        .subtract(1, "year")
        .year(),
      minDate: moment()
        .subtract(100, "year")
        .endOf("year")
        .toDate(),
      maxDate: moment()
        .subtract(1, "year")
        .endOf("year")
        .toDate()
    };
  },
  watch: {
    selectedDate(newDate) {
      const parsedDate = moment(newDate, "YYYY-MM-DD", true);
      if (parsedDate.isValid()) {
        this.isDobValid = true;
      } else {
        this.isDobValid = false;
      }
    }
  },
  computed: {
    ...mapGetters([" dateofbirth", "allProfile"])
  },
  mounted() {
    this.currentStep = this.step;
    const datePickerId = document.getElementById("dateField");
    datePickerId.addEventListener("click", e => {
      e.preventDefault();
    });
    datePickerId.max = new Date().toISOString().split("T")[0];
    if (window.innerWidth < 768) {
      this.responsive_view = true;
    }
    window.addEventListener("resize", this.handleResize);
    this.initData();
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    initData() {
      const profile = this.allProfile;
      if (profile) {
        this.selectedDate = profile.date_of_birth;
      }
      if (this.selectedDate) {
        this.$refs.dateInput.type = "date";
      } else {
        this.$refs.dateInput.type = "text";
      }
    },
    handleResize() {
      this.responsive_view = window.innerWidth < 768;
    },
    onNext(event) {
      event.preventDefault();
      const selectedDate = moment(this.selectedDate).toDate();
      if (selectedDate > this.minDate && selectedDate < this.maxDate) {
        let obj = {
          date_of_birth: this.selectedDate
        };
        this.updateOnboarding(obj, this.form);
      } else {
        this.isDobValid = false;
        this.errMsg = "Please enter a valid date";
      }
    },
    handleDate(date) {
      this.selectedDate = date;
    },
    handleFocus() {
      this.$refs.dateInput.type = "date";
    },
    handleBlur() {
      if (!this.selectedDate) {
        this.$refs.dateInput.type = "text";
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/scss/onboarding/_onboarding.scss";
.ob-date__birth {
  .form-floating {
    max-width: 572px;
    label {
      color: $brand-neutral-300;
    }
    input.form-control {
      background-color: $brand-primary-75;
      color: $brand-neutral-300;
      cursor: pointer;
    }
    input.form-control:hover {
      background-color: $brand-primary-100;
    }
    input.form-control.active {
      color: $brand-neutral-700;
    }
    input.form-control:focus {
      border: 1px solid $brand-primary;
      color: $brand-neutral-700;
      box-shadow: none;
    }
    input[type="date"] ~ label {
      color: $brand-primary; // Change this to the color you want
    }
    input::-webkit-datetime-edit-day-field:focus,
    input::-webkit-datetime-edit-month-field:focus,
    input::-webkit-datetime-edit-year-field:focus {
      color: $brand-neutral-700;
      background-color: $brand-neutral-300;
    }
  }
  .input-date__error {
    border: 1px solid $brand-error-400;
    input.form-control {
      color: $brand-neutral-700;
      &:focus {
        border-color: transparent;
      }
    }
  }
}
@-moz-document url-prefix() {
  .date-field::after {
    content: " ";
    position: absolute;
    background: #f4f9ff;
    width: 25px;
    height: 25px;
    right: 17px;
    top: 28px;
    pointer-events: none;
  }
  .date-field:hover::after,
  .date-field:focus::after {
    background: #e6f2ff;
  }
}
</style>
